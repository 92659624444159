import { lazy } from "react";
const DashboardNew = lazy(() => import("./pages/DashboardNew"));
const ComponentGroups = lazy(() => import("./pages/ComponentGroups"));
const PayrollCycle = lazy(() => import("./pages/PayrollCycle"));
const EmployeeDetails = lazy(() => import("./pages/EmployeeDetails"));
const EmployeePayPeriod = lazy(() => import("./pages/EmployeePayPeriod"));
const Modals = lazy(() => import("./pages/Modals"));
const BulkUpload = lazy(() => import("./pages/BulkUpload"));
const BulkUploadLeave = lazy(() => import("./pages/BulkUploadLeave"));
const Configuration = lazy(() => import("./pages/Configuration"));
const Configurations = lazy(() => import("./pages/Configurations"));
const MasterRoll = lazy(() => import("./pages/MusterRoll"));
const MonthlyReport = lazy(() => import("./pages/MonthlyReport"));
const AddOrganisation = lazy(() => import("./components/AddOrganisation"));
const Configurations2 = lazy(() => import("./pages/Configurations2"));
const Companylist = lazy(() => import("./pages/Companylist"));
const Holidaysettings = lazy(() => import("./pages/Holidaysettings"));
const ApplicationDetails = lazy(() => import("./pages/Applicationdetails"));
const ApplyLeave = lazy(() => import("./pages/Applyleave"));
const LeaveApplication = lazy(() => import("./pages/Leaveapplication"));
const LeaveTypes = lazy(() => import("./pages/Leavetypes"));
const LeaveBalance = lazy(() => import("./pages/Leavebalance"));
const Organisation = lazy(() => import("./pages/Organisation"));
const Region = lazy(() => import("./pages/Region"));
const Addnewregion = lazy(() => import("./pages/Addnewregion"));
const Departments = lazy(() => import("./pages/Departments"));
const Addnewdepartment = lazy(() => import("./pages/Addnewdepartment"));
const Designations = lazy(() => import("./pages/Designations"));
const Addnewdesignation = lazy(() => import("./pages/Addnewdesignation"));
const Structuremapping = lazy(() => import("./pages/Structuremapping"));
const Addnewmapping = lazy(() => import("./pages/Addnewmapping"));
const Client = lazy(() => import("./pages/Client"));
const Addnewclient = lazy(() => import("./pages/Addnewclient"));
const Qrmanagement = lazy(() => import("./pages/Qrmanagement"));
const Mapnewqrcode = lazy(() => import("./pages/Mapnewqrcode"));
const Employees = lazy(() => import("./pages/Employees"));
const Addnewemployee = lazy(() => import("./pages/Addnewemployee"));
const Registration = lazy(() => import("./pages/Registration"));
const AddNewShifts = lazy(() => import("./pages/Addnewshifts"));
const AddLeaveTypes = lazy(() => import("./pages/AddNewType"));
const PayrollStructures = lazy(() => import("./pages/PayrollStructures"));

const AddNewGroup = lazy(() => import("./pages/AddNewGroup"));
const AddNewPayrollCycle = lazy(() => import("./pages/AddNewPayrollCycle"));

const Components = lazy(() => import("./pages/PayRollComponents"));
const AddNewComponent = lazy(() => import("./pages/AddNewPayRollComponent"));
const EmployeePayStructure = lazy(() => import("./pages/EmployeePayStructure"));

const AddNewLevel = lazy(() => import("./pages/AddNewLevel"));
const UpdateSalary = lazy(() => import("./pages/UpdateSalary"));
const EmployeePaySlips = lazy(() => import("./pages/EmployeePaySlips"));
const LoanTypes = lazy(() => import("./pages/LoanTypes"));
const AddNewLoanType = lazy(() => import("./pages/AddNewLoanType"));
const LoanRequest = lazy(() => import("./pages/LoanRequest"));
const UserStructureMapping = lazy(() => import("./pages/UserStructureMapping"));
const AddNewLoanRequest = lazy(() => import("./pages/AddNewLoanRequest"));
const PayrollUserStructure = lazy(() => import("./pages/PayrollUserStructure"));

const DetailedView = lazy(() => import("./pages/DetailedView"));
const LoanRequestDetailView = lazy(() =>
  import("./pages/LoanRequestDetailView")
);
const LoanOverView = lazy(() => import("./pages/LoanOverView"));
const LoanOverViewDetailView = lazy(() =>
  import("./pages/LoanOverviewDetailView")
);
const AdvanceRequest = lazy(() => import("./pages/AdvanceRequest"));
const AddNewAdvanceRequest = lazy(() => import("./pages/AddNewAdvanceRequest"));
const AdvanceRequestDetailView = lazy(() =>
  import("./pages/AdvanceRequestDetailView")
);
const AdvanceOverView = lazy(() => import("./pages/AdvanceOverView"));
const AdvanceOverViewDetail = lazy(() =>
  import("./pages/AdvanceOverviewDetail")
);
const RoleManagement = lazy(() => import("./pages/RoleManagement"));
const RolePermssions = lazy(() => import("./pages/RolePermission"));
const User = lazy(() => import("./pages/User"));
const AddNewUser = lazy(() => import("./pages/AddNewUser"));
const UserRoleAssignment = lazy(() => import("./pages/UserRoleAssignment"));
const AddNewUserRole = lazy(() => import("./pages/AddNewUserRole"));
const AttendanceLogs = lazy(() => import("./pages/AttendanceLogs"));
// const Designation = lazy(() => import("./pages/Designation"));

const AttendanceConfiguration = lazy(() =>
  import("./pages/AttendanceConfiguration")
);
const LeaveConfiguration = lazy(() => import("./pages/LeaveConfiguration"));

const PayrollConfiguration = lazy(() => import("./pages/PayRollConfiguration"));

const LeaveApprovals = lazy(() => import("./pages/LeaveApprovals"));

const routes = [
  { path: "/Dashboard", exact: true, name: "Dashboard", element: DashboardNew },
  {
    path: "/Registration",
    exact: true,
    name: "Registration",
    element: Registration,
  },
  { path: "/Modals", exact: true, name: "Modals", element: Modals },

  {
    path: "/master-roll",
    exact: true,
    name: "MasterRoll",
    element: MasterRoll,
  },
  {
    path: "/holiday-settings",
    exact: true,
    name: "Holidaysettings",
    element: Holidaysettings,
  },
  {
    path: "/MonthlyReport",
    exact: true,
    name: "MonthlyReport",
    element: MonthlyReport,
  },
  {
    path: "/Company-list",
    exact: true,
    name: "Companylist",
    element: Companylist,
  },
  {
    path: "/AddOrganisation",
    exact: true,
    name: "AddOrganisation",
    element: AddOrganisation,
  },
  {
    path: "/Module/Attendance/bulkUpload",
    exact: true,
    name: "BulkUpload",
    element: BulkUpload,
  },
  {
    path: "/ComponentGroups",
    exact: true,
    name: "Component Groups",
    element: ComponentGroups,
  },
  {
    path: "/PayrollCycle",
    exact: true,
    name: "Payroll Cycle",
    element: PayrollCycle,
  },
  {
    path: "/PayPeriod",
    exact: true,
    name: "Employee pay period",
    element: EmployeePayPeriod,
  },

  {
    path: "/configurations",
    exact: true,
    name: "Configurations",
    element: Configurations,
  },
  {
    path: "/configurations2",
    exact: true,
    name: "Configurations2",
    element: Configurations2,
  },
  {
    path: "/applicationDetails",
    exact: true,
    name: "Applicationdetails",
    element: ApplicationDetails,
  },
  { path: "/applyLeave", exact: true, name: "ApplyLeave", element: ApplyLeave },
  {
    path: "/leaveApplication",
    exact: true,
    name: "LeaveApplication",
    element: LeaveApplication,
  },
  {
    path: "/configuration",
    exact: true,
    name: "Configuration",
    element: Configuration,
  },
  { path: "/leaveTypes", exact: true, name: "LeaveTypes", element: LeaveTypes },

  {
    path: "/leavebalance",
    exact: true,
    name: "LeaveBalance",
    element: LeaveBalance,
  },
  {
    path: "/bulkUploadLeave",
    exact: true,
    name: "BulkUpload",
    element: BulkUploadLeave,
  },
  {
    path: "/Organisation",
    exact: true,
    name: "Organisation",
    element: Organisation,
  },

  {
    path: "/AddNewType",
    exact: true,
    name: "AddNewType",
    element: AddLeaveTypes,
  },
  {
    path: "/Setup/OrganisationMasters/Region",
    exact: true,
    name: "Region",
    element: Region,
  },
  {
    path: "/Addnewregion",
    exact: true,
    name: "Addnewregion",
    element: Addnewregion,
  },
  {
    path: "/AddNewGroup",
    exact: true,
    name: "Add New Group",
    element: AddNewGroup,
  },
  {
    path: "/AddNewPayrollCycle",
    exact: true,
    name: "Add New Payroll Cycle",
    element: AddNewPayrollCycle,
  },
  {
    path: "/Setup/OrganisationMasters/Departments",
    exact: true,
    name: "Departments",
    element: Departments,
  },
  {
    path: "/Addnewdepartment",
    exact: true,
    name: "Addnewdepartment",
    element: Addnewdepartment,
  },
  {
    path: "/Setup/OrganisationMasters/Designations",
    exact: true,
    name: "Designations",
    element: Designations,
  },
  {
    path: "/Addnewdesignation",
    exact: true,
    name: "Addnewdesignation",
    element: Addnewdesignation,
  },
  {
    path: "/Setup/OrganisationMasters/Structure-mapping",
    exact: true,
    name: "Structuremapping",
    element: Structuremapping,
  },
  {
    path: "/Addnewmapping",
    exact: true,
    name: "Addnewmapping",
    element: Addnewmapping,
  },
  { path: "/Client", exact: true, name: "Client", element: Client },
  {
    path: "/Addnewclient",
    exact: true,
    name: "Addnewclient",
    element: Addnewclient,
  },
  {
    path: "/Qrmanagement",
    exact: true,
    name: "Qrmanagement",
    element: Qrmanagement,
  },
  {
    path: "/Mapnewqrcode",
    exact: true,
    name: "Mapnewqrcode",
    element: Mapnewqrcode,
  },
  {
    path: "/Setup/OrganisationMasters/Employees",
    exact: true,
    name: "Employees",
    element: Employees,
  },
  {
    path: "/Addnewemployee",
    exact: true,
    name: "Addnewemployee",
    element: Addnewemployee,
  },
  {
    path: "/EmployeePayPeriodDetails",
    exact: true,
    name: "Employee Pay Period Details",
    element: EmployeeDetails,
  },
  {
    path: "/AddNewShifts",
    exact: true,
    name: "AddNewShifts",
    element: AddNewShifts,
  },
  {
    path: "/Components",
    exact: true,
    name: "Components",
    element: Components,
  },

  {
    path: "/AddNewComponent",
    exact: true,
    name: "AddNewComponent",
    element: AddNewComponent,
  },

  {
    path: "/Employee-Pay-Structure",
    exact: true,
    name: "EmployeePayStructure",
    element: EmployeePayStructure,
  },

  {
    path: "/PayrollStructure",
    exact: true,
    name: "PayrollStructures",
    element: PayrollStructures,
  },
  {
    path: "/AddNewStructure",
    exact: true,
    name: "AddNewLevel",
    element: AddNewLevel,
  },
  {
    path: "/UpdateSalary",
    exact: true,
    name: "UpdateSalary",
    element: UpdateSalary,
  },

  {
    path: "/UserStructureMapping",
    exact: true,
    name: "UserStructureMapping",
    element: UserStructureMapping,
  },
  {
    path: "/payroll-structure",
    exact: true,
    name: "PayrollUserStructure",
    element: PayrollUserStructure,
  },

  {
    path: "/Employee-Pay-Slips",
    exact: true,
    name: "EmployeePaySlips",
    element: EmployeePaySlips,
  },
  {
    path: "/Detailed-View",
    exact: true,
    name: "Detailed-View",
    element: DetailedView,
  },
  {
    path: "/LoanTypes",
    exact: true,
    name: "Loan-Types",
    element: LoanTypes,
  },
  {
    path: "/AddNewLoanType",
    exact: true,
    name: "AddNewLoanType",
    element: AddNewLoanType,
  },
  {
    path: "/LoanRequest",
    exact: true,
    name: "LoanRequest",
    element: LoanRequest,
  },

  {
    path: "/AddNewLoanRequest",
    exact: true,
    name: "Add New Loan Request",
    element: AddNewLoanRequest,
  },

  {
    path: "/LoanRequestDetailView",
    exact: true,
    name: "LoanRequestDetailView",
    element: LoanRequestDetailView,
  },
  {
    path: "/LoanOverView",
    exact: true,
    name: "LoanOverView",
    element: LoanOverView,
  },
  {
    path: "/LoanOverViewDetailView",
    exact: true,
    name: "/LoanOverViewDetailView",
    element: LoanOverViewDetailView,
  },

  {
    path: "/AdvanceRequests",
    exact: true,
    name: "AdvanceRequests",
    element: AdvanceRequest,
  },
  {
    path: "/AddNewAdvanceRequest",
    exact: true,
    name: "AddNewAdvanceRequest",
    element: AddNewAdvanceRequest,
  },

  {
    path: "/AdvanceRequestDetailView",
    exact: true,
    name: "AdvanceRequestDetailView",
    element: AdvanceRequestDetailView,
  },
  {
    path: "/AdvanceOverView",
    exact: true,
    name: "AdvanceOverView",
    element: AdvanceOverView,
  },
  {
    path: "/AdvanceOverViewDetailView",
    exact: true,
    name: "AdvanceOverViewDetailView",
    element: AdvanceOverViewDetail,
  },
  {
    path: "/Setup/UserAccessControl/Role-Management",
    exact: true,
    name: "Role-Management",
    element: RoleManagement,
  },
  {
    path: "/Role-Permissions",
    exact: true,
    name: "Role Permissions",
    element: RolePermssions,
  },
  {
    path: "/Setup/UserAccessControl/User",
    exact: true,
    name: "User",
    element: User,
  },
  {
    path: "/AddNewUser",
    exact: true,
    name: "AddNewUser",
    element: AddNewUser,
  },
  {
    path: "/UserRoleAssignment",
    exact: true,
    name: "UserRoleAssignment",
    element: UserRoleAssignment,
  },
  {
    path: "/AddNewUserRole",
    exact: true,
    name: "AddNewUserRole",
    element: AddNewUserRole,
  },
  {
    path: "/Attendance-Logs",
    exact: true,
    name: "Attendance-Logs",
    element: AttendanceLogs,
  },
  {
    path: "/AttendanceConfiguration",
    exact: true,
    name: "AttendanceConfiguration",
    element: AttendanceConfiguration,
  },
  {
    path: "/LeaveConfiguration",
    exact: true,
    name: "LeaveConfiguration",
    element: LeaveConfiguration,
  },
  {
    path: "/PayrollConfiguration",
    exact: true,
    name: "PayrollConfiguration",
    element: PayrollConfiguration,
  },
  // {
  //   path: "/Designation",
  //   exact: true,
  //   name: "Designation",
  //   element: Designation,
  // },

  {
    path: "/leaveApprovals",
    exact: true,
    name: "LeaveApprovals",
    element: LeaveApprovals,
  },
];

export default routes;
