import React, { useContext, useEffect, useState } from "react";

import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useOutlet,
} from "react-router-dom";

import { useAuth } from "../hooks/useAuth";
import NotificationMessage from "../components/NotificationMessage";

import { Button, Modal, Avatar, SelectPicker, toaster } from "rsuite";

import Form from "react-bootstrap/Form";

import { IoSearch } from "react-icons/io5";
import { TbLogout } from "react-icons/tb";
import { MdLogout } from "react-icons/md";
import { FiChevronRight } from "react-icons/fi";

import {
  Container,
  Header,
  Sidebar,
  Sidenav,
  Content,
  Navbar,
  Nav,
  Dropdown,
  DOMHelper,
  List,
  
} from "rsuite";

import Footer from "rsuite/Footer";

import { nav } from "../data/nav";

import { navTwo } from "../data/navTwo";

import { HiOutlineChatAlt2, HiUserCircle } from "react-icons/hi";

import AppContent from "./AppContent";
import MultiProgressBar from "./Dashboard/MultiProgressBar";
import { navModule, navOne } from "../data/navModule";
import { navAdminPages, reports, Reports } from "../data/navReports";
import { navSetup } from "../data/navSetup";
import { FiLink2 } from "react-icons/fi";
import { Breadcrumb } from "rsuite";
import BreadCrum from "./BreadCrum";
import { useDispatch, useSelector } from "react-redux";
import { s, title } from "framer-motion/client";
import Cookies from "js-cookie";
import { handleOnboardingStatus } from "../Redux/userSlice";
import { AiFillStar, AiOutlineStar } from "react-icons/ai";
import { useMutation, useQuery } from "@tanstack/react-query";
import api from "../lib/api";
import { PiChartPieSlice } from "react-icons/pi";



const { getHeight, getWidth } = DOMHelper;


const ProtectedLayout = () => {
  const customerDetails = Cookies.get("customerDetails");
  // console.log(customerDetails, "customerDetails");

  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  console.log("userDetails", userDetails);

  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [expand, setExpand] = useState(true);
  const [mobile, setMobile] = useState(false);
  const [mobileExpand, setMobileExpand] = useState(false);
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState("favorite");
  const [isFavorite, setIsFavorite] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const[selectedModules, setSelectedModules] = useState([]);
 
  console.log(selectedModules, "selectedModules");
  const { userData } = useSelector((state) => state.rootReducer);
  console.log("userData redux", userData);

  const [recently, setRecently] = useState([
    {
      name: "Overview",
      path: "/dashboard",
    },
  ]);

  const { user, logout } = useAuth();
  const [newWidth, setNewWidth] = useState();
  const [favourite, setFavourite] = useState([
    { name: "Overview", path: "/Overview" },
  ]);

  const outlet = useOutlet();

  const { onboardingStatus } = useSelector((state) => state.rootReducer);

  //  const navModule = [
  //    {
  //      title: "Attendance",
  //      icon: <PiChartPieSlice className="new-icn" />,
  //      // menu: [
  //      //   {
  //      //     title: "Configurations",
  //      //     link: "/configurations",
  //      //   },

  //      //   {
  //      //     title: "Team Attendance",
  //      //     link: "/Team-Attendance",
  //      //   },

  //      //   {
  //      //     title: "Manual Attendance",
  //      //     link: "/Manual-Attendance",
  //      //   },

  //      //   {
  //      //     title: "Attendance Approval",
  //      //     link: "/BulkUpload",
  //      //   },

  //      //   {
  //      //     title: "Bulk Upload",
  //      //     link: "/Module/Attendance/bulkUpload",
  //      //   },

  //      //   {
  //      //     title: "Workflow",
  //      //     link: "/Workflow",
  //      //   },
  //      // ],
  //      menu: selectedModules[0]?.features?.map((item) => ({
  //        title: item.name,
  //        link: `/${selectedModules[0]?.module?.replace(
  //          /\s+/g,
  //          ""
  //        )}/${item.name.replace(/\s+/g, "")}`,
  //        isAdded: item.isAdded,
  //      })),
  //    },
  //    {
  //      title: "Leave",
  //      icon: <PiChartPieSlice className="new-icn" />,

  //      //  menu: [
  //      //    {
  //      //      title: "Configurations",
  //      //      link: "/configurations",
  //      //    },
  //      //    {
  //      //      title: "Leave Types",
  //      //      link: "/leaveTypes",
  //      //    },

  //      //    {
  //      //      title: "Leave Balance",
  //      //      link: "/leavebalance",
  //      //    },

  //      //    {
  //      //      title: "Manage Leaves",
  //      //      link: "/Manage-Leaves",
  //      //    },

  //      //    {
  //      //      title: "Holiday List",
  //      //      link: "/holiday-settings",
  //      //    },
  //      //    {
  //      //      title: "Bulk Upload",
  //      //      link: "/bulkUploadLeave",
  //      //    },
  //      //    {
  //      //      title: "Workflow",
  //      //      link: "/Workflow",
  //      //    },
  //      //    {
  //      //      title: "Leave Application",
  //      //      link: "/leaveApplication",
  //      //    },

  //      //    {
  //      //      title: "Apply Leave",
  //      //      link: "/applyLeave",
  //      //    },

  //      //    {
  //      //      title: "Application Details",
  //      //      link: "/applicationDetails",
  //      //    },
  //      //  ],
  //      menu: selectedModules[5]?.features?.map((item) => ({
  //        title: item.name,
  //        link: `/${selectedModules[0]?.module?.replace(
  //          /\s+/g,
  //          ""
  //        )}/${item.name.replace(/\s+/g, "")}`,
  //        isAdded: item.isAdded,
  //      })),
  //    },
  //    // {
  //    //   title: "Payroll",
  //    //   icon: <PiChartPieSlice className="new-icn" />,

  //    //   menu: [
  //    //     {
  //    //       title: "Payroll Structures",
  //    //       link: "/PayrollStructure",
  //    //     },
  //    //     {
  //    //       title: "Component Groups",
  //    //       link: "/ComponentGroups",
  //    //     },
  //    //     {
  //    //       title: "Components",
  //    //       link: "/Components",
  //    //     },
  //    //     {
  //    //       title: "User Structure Mapping",
  //    //       link: "/UserStructureMapping",
  //    //     },
  //    //     {
  //    //       title: "Employee Pay Structure",
  //    //       link: "/Employee-Pay-Structure",
  //    //     },
  //    //     {
  //    //       title: "Payroll Cycle",
  //    //       link: "/PayrollCycle",
  //    //     },
  //    //     {
  //    //       title: "Employee pay period",
  //    //       link: "/PayPeriod",
  //    //     },

  //    //     {
  //    //       title: "Employee PaySlips",
  //    //       link: "/Employee-Pay-Slips",
  //    //     },
  //    //   ],
  //    // },

  //    {
  //      title: "Loans",
  //      icon: <PiChartPieSlice className="new-icn" />,

  //      menu: [
  //        {
  //          title: "Loan Types",
  //          link: "/LoanTypes",
  //        },
  //        {
  //          title: "Loan Request",
  //          link: "/LoanRequest",
  //        },
  //        {
  //          title: "Loan OverView",
  //          link: "/LoanOverView",
  //        },
  //      ],
  //    },
  //    {
  //      title: "Advances",
  //      icon: <PiChartPieSlice className="new-icn" />,

  //      menu: [
  //        {
  //          title: "Advance Requests",
  //          link: "/AdvanceRequests",
  //        },
  //        {
  //          title: "Advance OverView",
  //          link: "/AdvanceOverView",
  //        },
  //      ],
  //    },
  //  ];

  const pages = [
    { name: "Dashboard", route: "/dashboard" },
    { name: "Employees", route: "/Setup/OrganisationMasters/Employees" },
    { name: "Overview", route: "/Dashboard" },
    { name: "Organisations", route: "/Organisation" },
    { name: "Regions", route: "/Setup/OrganisationMasters/Region" },
    { name: "Departments", route: "/Departments" },
    {
      name: "Structure Mapping",
      route: "/Setup/OrganisationMasters/Structure-mapping",
    },
    { name: "Designations", route: "/Setup/OrganisationMasters/Designations" },
    { name: "Configuration", route: "/configuration" },
    { name: "Clients", route: "/Client" },
    { name: "Client Overview", route: "/Client-Overview" },
    {
      name: "Role Management",
      route: "/Setup/UserAccessControl/Role-Management",
    },
    { name: "Role Permissions", route: "/Role-Permissions" },
    { name: "User", route: "/Setup/UserAccessControl/User" },
    { name: "User Role Assignment", route: "/UserRoleAssignment" },
    { name: "Configurations", route: "/Configurations" },
    { name: "Team Attendance", route: "/Team-Attendance" },
    { name: "Manual Attendance", route: "/Manual-Attendance" },
    { name: "Bulk Upload", route: "/Module/Attendance/bulkUpload" },
    { name: "Leave Application", route: "/leaveApplication" },
    { name: "Leave balance", route: "/leavebalance" },
    { name: "Manage Leaves", route: "/Manage-Leaves" },
    { name: "Holiday List", route: "/holiday-settings" },
    { name: "BulkUpload Leave", route: "/bulkUploadLeave" },
    { name: "Apply Leave", route: "/applyLeave" },
    { name: "Payroll Structure", route: "/PayrollStructure" },
    { name: "Component Groups", route: "/ComponentGroups" },
    { name: "Components", route: "/Components" },
    { name: "User Structure Mapping", route: "/UserStructureMapping" },
    { name: "Employee PayStructure", route: "/Employee-Pay-Structure" },
    { name: "Payroll Cycle", route: "/PayrollCycle" },
    { name: "PayPeriod", route: "/PayPeriod" },
    { name: "Employee PaySlips", route: "/Employee-Pay-Slips" },
    { name: "Loan Types", route: "/LoanTypes" },
    { name: "Loan Request", route: "/LoanRequest" },
    { name: "Advance Requests", route: "/AdvanceRequests" },
    { name: "Advance OverView", route: "/AdvanceOverView" },
    { name: "Loan OverView", route: "/LoanOverView" },
  ];

  const {
    data: featuresAndPrivilages = {},
    isLoading: featuresAndPrivilagesLoading,
    refetch: refetchApi,
  } = useQuery(
    ["getAllFeaturesAndPrivilages"],
    async () => {
      const response = await api.get(
        `master-service/v1/assigned/privilages/roles/${user?.roleId}`
      );
      return response.data.featuresAndPrivilages;
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: user?.roleId !== null,

      onError: (err) => {
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)}
          />,
          { placement: "bottomEnd", duration: 2000 }
        );

        setTimeout(() => {
          toaster.remove(notificationKey);
        }, 2000);
      },
    }
  );
useEffect(() => {
  if (featuresAndPrivilages) {
    const modulesMap = new Map();

    Object.entries(featuresAndPrivilages).forEach(([moduleName, features]) => {
      const moduleFeatures = features.map((feature) => {
        const hasPrivilege = feature.privilages
          .filter((privilege) => privilege.isAdded) // Filter privileges with isAdded true
          .map((privilege) => privilege.name);

        return {
          name: feature.feature, // Feature name
          isAdded: hasPrivilege, // True if any privilege under the feature is added
        };
      });

      // Set the module name with its features or an empty array
      modulesMap.set(moduleName, moduleFeatures);
    });

    // Convert Map to array of objects
    const selectedModulesArray = Array.from(
      modulesMap,
      ([module, features]) => ({
        module,
        features, // Features array with name and isAdded properties (can be empty)
      })
    );

    setSelectedModules(selectedModulesArray);
  }
}, []);

  useEffect(() => {
    if (user && onboardingStatus) {
      if (
        onboardingStatus.customer === true &&
        onboardingStatus.region === true &&
        onboardingStatus.department === true &&
        onboardingStatus.structureMapping === true &&
        onboardingStatus.designation === true
      ) {
        // console.log("customer");
      } else {
        navigate("/registration");
      }
    }
  }, [user, onboardingStatus]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

    const filteredPages = pages.filter((page) =>
      page.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    const handleSearch = (route) => {
      navigate(route);
      setSearchQuery(""); // Clear search bar after navigation
    };

  const formatRouteSegment = (segment) => {
    if (segment.includes("-") || segment.includes("_")) {
      return segment
        .split(/[-_]/)
        .map(
          (word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        )
        .join(" ");
    }

    const words = segment.split(
      /(?<=[a-z])(?=[A-Z])|(?<=[A-Z])(?=[A-Z][a-z])|\d+/g
    );

    return words
      .map((word) => {
       
        if (/^\d+$/.test(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  const handleResize = () => {
    if (window.innerWidth < 767) {
      setMobile(true);
    } else {
      setMobile(false);
    }
  };

  useEffect(() => {
    const pathNames = location.pathname.split("/").filter((x) => x);
    const breadcrumbsArray = [{ name: "Home", path: "/" }];

    pathNames.forEach((name, index) => {
      const path = `/${pathNames.slice(0, index + 1).join("/")}`;
      const displayName = formatRouteSegment(name);
      breadcrumbsArray.push({ name: displayName, path });
    });

    setBreadcrumbs(breadcrumbsArray);
  }, [location]);

  useEffect(() => {
    setIsFavorite(location.pathname === favourite.path);
  }, [location, favourite]);

  const handleSetRecently = (name, path) => {
    setRecently((prevRecently) => {
      const updatedRecently = prevRecently.filter((item) => item.path !== path);

      updatedRecently.unshift({ name, path });

      const recentList = updatedRecently.slice(0, 2);

      localStorage.setItem("recently", JSON.stringify(recentList));

      return recentList;
    });
  };

  useEffect(() => {
    const currentName = location.pathname.split("/").pop() || "Home";
    const currentPath = location.pathname;

    handleSetRecently(currentName, currentPath);
  }, [location]);

  
  useEffect(() => {
    if (window.innerWidth < 767) {
      setMobile(true);
    }
    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    // Check if the current path is already a favorite
    const exists = favourite.some((fav) => fav.path === location.pathname);
    setIsFavorite(exists);
  }, [location.pathname, favourite]);

  useEffect(() => {
    // Fetch token from localStorage on component mount
   
    const newRecently = localStorage.getItem("recently");
    const newFavorite = localStorage.getItem("favorite");
    if (newRecently) {
      const parsedRecently = JSON.parse(newRecently);
      if (Array.isArray(parsedRecently)) {
        setRecently(parsedRecently);
      } else {
        setRecently([]);
      }
    }
    if (newFavorite) {
      const parsedFavorite = JSON.parse(newFavorite);
      if (Array.isArray(parsedFavorite)) {
        setFavourite(parsedFavorite);
      } else {
        setFavourite([]);
      }
    }
  }, []);

 

  // console.log("user ", user);
  // let tokenVerify = Cookies.get("TopHrToken");
  // if (!tokenVerify || !user) {
    if (!user) {
      console.log("navigate to login");
      return <Navigate to="/login" />;
    }

  const handleFavourite = (name, path) => {
    // Check if the item already exists in favourites
    const exists = favourite.some((fav) => fav.path === path);

    if (exists) {
      // If it exists, remove it from the favourites
      const updatedFavourites = favourite.filter((fav) => fav.path !== path);
      setFavourite(updatedFavourites);
      setIsFavorite(false);
      localStorage.setItem("favorite", JSON.stringify(updatedFavourites));
    } else {
      // If it's not a favorite, add it to the favourites (up to 2)
      const updatedFavourites = [...favourite, { name, path }].slice(-2);
      setFavourite(updatedFavourites);
      setIsFavorite(true);
      localStorage.setItem("favorite", JSON.stringify(updatedFavourites));
    }
  };

  const handleFavourite2 = () => {
    const currentName = location.pathname.split("/").pop() || "Home";
    const currentPath = location.pathname;
    handleFavourite(currentName, currentPath);
  };

  const data = [
    "Today",
    "Tommorrow",
    "Yesterday",
    "Last Week",
    "Last Month",
    "Last Year",
  ].map((item) => ({ label: item, value: item }));

  return (
    <>
      <Container>
        <div
          className={`d-flex main ${
            expand ? "sidebar-expanded" : "sidebar-collapsed"
          }`}
        >
          <div className={`${expand ? "" : "toggle"}`}>
            <Container>
              <Sidebar
                style={{ display: "flex", flexDirection: "column" }}
                width={mobileExpand ? 200 : mobile ? 0 : expand ? 200 : 50}
                collapsible

                // onMouseOver={() => {
                //   setExpand(true);
                // }}
                // onMouseOut={() => {
                //   setExpand(false);
                // }}
              >
                <Sidenav
                  expanded={expand}
                  appearance="subtle"
                  style={{
                    width: mobileExpand ? 200 : mobile ? 0 : expand ? 200 : 50,
                    padding: mobile ? 0 : "0px 9px",
                  }}
                >
                  <div
                    className="d-flex align-items-center us-name"
                    style={{
                      width: "208px",
                      height: "28px",
                      gap: "10.25px",
                      paddingTop: "26px",
                      // paddingLeft: "24px",
                      paddingLeft: "3px",
                    }}
                  >
                    {/* <img
                      style={{ marginTop: "3px", maxWidth: "35px" }}
                      src={
                        userData.image
                        ? `data:image/jpeg;base64,${userData.image}`
                        : "/images/newProfile.png"
                      }
                    ></img> */}

                    <Avatar
                      circle
                      src={
                        userData.image
                          ? `data:image/jpeg;base64,${userData.image}`
                          : null
                      }
                      style={{
                        width: "30px",
                        height: "30px",
                      }}
                    />

                    {expand ? (
                      <div
                        style={{
                          width: "160px",
                          height: "14px",
                          fontSize: "13px",
                          fontWeight: 600,
                        }}
                      >
                        {userData?.userName
                          ? userData.userName
                              .split(" ")
                              .map(
                                (word) =>
                                  word.charAt(0).toUpperCase() + word.slice(1)
                              )
                              .join(" ")
                          : ""}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                  <span
                    style={{
                      width: "176px",
                      borderBottom: "1px solid black",
                      color: "#1C1C1C",
                      paddingTop: "22px",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                    }}
                  ></span>

                  {expand ? (
                    <div
                      className="recent-section"
                      style={{ width: "208px", height: "114px" }}
                    >
                      <div
                        className="d-flex"
                        style={{ width: "208px", height: "26px", gap: "8px" }}
                      >
                        <div
                          onClick={() => setActiveTab("favorite")}
                          style={{
                            cursor: "pointer",
                            fontWeight:
                              activeTab === "favorite" ? "bold" : "normal",
                            color: activeTab === "favorite" ? "#000" : "#666",
                            marginRight: "10px",
                            transition: "all 0.3s ease",
                            width: "100px",
                            fontSize: "13px",
                            weight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Favorites
                        </div>

                        <div
                          onClick={() => setActiveTab("recently")}
                          style={{
                            cursor: "pointer",
                            fontWeight:
                              activeTab === "recent" ? "bold" : "normal",
                            color: activeTab === "recent" ? "#000" : "#666",
                            transition: "all 0.3s ease",
                            width: "100px",
                            fontSize: "13px",
                            weight: 400,
                            color: "#A4A4A4",
                          }}
                        >
                          Recent
                        </div>
                      </div>

                      <ul
                        className="m-0"
                        style={{ position: "relative", left: "32px" }}
                      >
                        {activeTab === "favorite" ? (
                          <>
                            {favourite.map((fav, index) => (
                              <li key={index}>
                                <Link
                                  to={fav.path}
                                  style={{ fontSize: "14px", fontWeight: 400 }}
                                >
                                  {fav.name}
                                </Link>
                              </li>
                            ))}
                          </>
                        ) : (
                          <>
                            {recently.map((recent, index) => (
                              <li key={index}>
                                <Link
                                  to={recent.path}
                                  style={{ fontSize: "14px", fontWeight: 400 }}
                                >
                                  {recent.name}
                                </Link>
                              </li>
                            ))}
                          </>
                        )}
                      </ul>
                    </div>
                  ) : (
                    <></>
                  )}

                  {expand ? (
                    <div className="nav-heading">Dashboard</div>
                  ) : (
                    <></>
                  )}

                  <Sidenav.Body>
                    <Nav>
                      {nav.map((item, index) => {
                        if (item.menu) {
                          return (
                            <Nav.Menu
                              key={index}
                              eventKey={`nav${index}`}
                              title={item.title}
                              icon={item.icon}
                              className="custom-menu"
                            >
                              {item.menu.map((item2, index2) => {
                                return (
                                  <Nav.Item key={index2}>
                                    <Link to={item2.link}>{item2.title}</Link>
                                  </Nav.Item>
                                );
                              })}
                            </Nav.Menu>
                          );
                        } else {
                          return (
                            <Nav.Item
                              key={index}
                              eventKey={index}
                              icon={item.icon}
                            >
                              <Link to={item.link}> {item.title}</Link>
                            </Nav.Item>
                          );
                        }
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Setup</div> : <></>}

                  <Sidenav.Body>
                    <Nav>
                      {navSetup.map((navItem, navIndex) => {
                       
                        {/* console.log("Processing navItem:", navItem); */}

                        // Default permissions to be applied
                        const defaultPermissions = [
                          "read",
                          "write",
                          "update",
                          "delete",
                        ];

                        // Check if navItem.menu exists
                        if (!navItem.menu || navItem.menu.length === 0) {
                          {/* console.warn(
                            `navItem ${navItem.title} has no menu items.`
                          ); */}
                          return null;
                        }

                        // Find roleModule or create a default one
                        const roleModule = selectedModules.find(
                          (roleItem) => roleItem.module === navItem.title
                        );

                        const effectiveRoleModule = roleModule || {
                          module: navItem.title,
                          features: navItem.menu.map((feature) => ({
                            name: feature.title,
                            isAdded: defaultPermissions, // Default permissions
                          })),
                        };

                        {/* console.log(
                          "Effective Role Module:",
                          effectiveRoleModule
                        ); */}

                        // Map and filter features
                        const filteredFeatures = navItem.menu.map((feature) => {
                          // Debug: Log the current feature
                          {/* console.log("Processing feature:", feature); */}

                          const roleFeature =
                            effectiveRoleModule.features?.find(
                              (roleFeature) =>
                                roleFeature.name === feature.title
                            );

                          // Assign default permissions if feature not found
                          return {
                            ...feature,
                            isAdded: roleFeature?.isAdded || defaultPermissions,
                          };
                        });

                        {/* console.log("Filtered Features:", filteredFeatures); */}

                        // Filter out features with no permissions (if applicable)
                        const validFeatures = filteredFeatures.filter(
                          (feature) =>
                            Array.isArray(feature.isAdded) &&
                            feature.isAdded.length > 0
                        );

                        if (validFeatures.length === 0) {
                          {/* console.warn(
                            `No valid features for navItem ${navItem.title}.`
                          ); */}
                          return null;
                        }

                        return (
                          <Nav.Menu
                            key={`nav-${navIndex}`}
                            title={navItem.title}
                            icon={navItem.icon}
                            eventKey={`nav-${navIndex}`}
                            className="custom-menu"
                          >
                            {validFeatures.map((feature, featureIndex) => (
                              <Nav.Item
                                key={`feature-${navIndex}-${featureIndex}`}
                                eventKey={`feature-${navIndex}-${featureIndex}`}
                                onClick={() =>
                                  handleSetRecently(feature.title, feature.link)
                                }
                              >
                                <li
                                  style={{
                                    listStyleType: "disc",
                                    fontSize: "15px",
                                    color: "#B0B0B0",
                                  }}
                                >
                                  <Link
                                    to={feature?.link}
                                    state={{ isAdded: feature?.isAdded }}
                                  >
                                    {feature.title}
                                  </Link>
                                </li>
                              </Nav.Item>
                            ))}
                          </Nav.Menu>
                        );
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Modules</div> : <></>}
                  <Sidenav.Body>
                    <Nav>
                      {navModule.map((navItem, navIndex) => {
                        // Debug: Log the current navItem
                        {/* console.log("Processing navItem:", navItem); */}

                        // Default permissions to be applied
                        const defaultPermissions = [
                          "read",
                          "write",
                          "update",
                          "delete",
                        ];

                        // Check if navItem.menu exists
                        if (!navItem.menu || navItem.menu.length === 0) {
                          {/* console.warn(
                            `navItem ${navItem.title} has no menu items.`
                          ); */}
                          return null;
                        }

                        // Find roleModule or create a default one
                        const roleModule = selectedModules.find(
                          (roleItem) => roleItem.module === navItem.title
                        );

                        const effectiveRoleModule = roleModule || {
                          module: navItem.title,
                          features: navItem.menu.map((feature) => ({
                            name: feature.title,
                            isAdded: defaultPermissions, // Default permissions
                          })),
                        };

                        {/* console.log(
                          "Effective Role Module:",
                          effectiveRoleModule
                        ); */}

                        // Map and filter features
                        const filteredFeatures = navItem.menu.map((feature) => {
                          // Debug: Log the current feature
                          {/* console.log("Processing feature:", feature); */}

                          const roleFeature =
                            effectiveRoleModule.features?.find(
                              (roleFeature) =>
                                roleFeature.name === feature.title
                            );

                          // Assign default permissions if feature not found
                          return {
                            ...feature,
                            isAdded: roleFeature?.isAdded || defaultPermissions,
                          };
                        });

                        {/* console.log("Filtered Features:", filteredFeatures); */}

                        // Filter out features with no permissions (if applicable)
                        const validFeatures = filteredFeatures.filter(
                          (feature) =>
                            Array.isArray(feature.isAdded) &&
                            feature.isAdded.length > 0
                        );

                        if (validFeatures.length === 0) {
                          {/* console.warn(
                            `No valid features for navItem ${navItem.title}.`
                          ); */}
                          return null;
                        }

                        return (
                          <Nav.Menu
                            key={`nav-${navIndex}`}
                            title={navItem.title}
                            icon={navItem.icon}
                            eventKey={`nav-${navIndex}`}
                            className="custom-menu"
                          >
                            {validFeatures.map((feature, featureIndex) => (
                              <Nav.Item
                                key={`feature-${navIndex}-${featureIndex}`}
                                eventKey={`feature-${navIndex}-${featureIndex}`}
                                onClick={() =>
                                  handleSetRecently(feature.title, feature.link)
                                }
                              >
                                <li
                                  style={{
                                    listStyleType: "disc",
                                    fontSize: "15px",
                                    color: "#B0B0B0",
                                  }}
                                >
                                  <Link
                                    to={feature?.link}
                                    state={{ isAdded: feature?.isAdded }}
                                  >
                                    {feature.title}
                                  </Link>
                                </li>
                              </Nav.Item>
                            ))}
                          </Nav.Menu>
                        );
                      })}
                    </Nav>
                  </Sidenav.Body>

                  {expand ? <div className="nav-heading">Reports</div> : <></>}
                  <Sidenav.Body>
                    <Nav>
                      {reports.map((navItem, navIndex) => {
                        // Debug: Log the current navItem
                        {/* console.log("Processing navItem:", navItem); */}

                        // Default permissions to be applied
                        const defaultPermissions = [
                          "read",
                          "write",
                          "update",
                          "delete",
                        ];

                        // Check if navItem.menu exists
                        if (!navItem.menu || navItem.menu.length === 0) {
                          {/* console.warn(
                            `navItem ${navItem.title} has no menu items.`
                          ); */}
                          return null;
                        }

                        // Find roleModule or create a default one
                        const roleModule = selectedModules.find(
                          (roleItem) => roleItem.module === navItem.title
                        );

                        const effectiveRoleModule = roleModule || {
                          module: navItem.title,
                          features: navItem.menu.map((feature) => ({
                            name: feature.title,
                            isAdded: defaultPermissions, // Default permissions
                          })),
                        };

                        {/* console.log(
                          "Effective Role Module:",
                          effectiveRoleModule
                        ); */}

                        // Map and filter features
                        const filteredFeatures = navItem.menu.map((feature) => {
                          // Debug: Log the current feature
                          {/* console.log("Processing feature:", feature); */}

                          const roleFeature =
                            effectiveRoleModule.features?.find(
                              (roleFeature) =>
                                roleFeature.name === feature.title
                            );

                          // Assign default permissions if feature not found
                          return {
                            ...feature,
                            isAdded: roleFeature?.isAdded || defaultPermissions,
                          };
                        });

                        {/* console.log("Filtered Features:", filteredFeatures); */}

                        // Filter out features with no permissions (if applicable)
                        const validFeatures = filteredFeatures.filter(
                          (feature) =>
                            Array.isArray(feature.isAdded) &&
                            feature.isAdded.length > 0
                        );

                        if (validFeatures.length === 0) {
                          {/* console.warn(
                            `No valid features for navItem ${navItem.title}.`
                          ); */}
                          return null;
                        }

                        return (
                          <Nav.Menu
                            key={`nav-${navIndex}`}
                            title={navItem.title}
                            icon={navItem.icon}
                            eventKey={`nav-${navIndex}`}
                            className="custom-menu"
                          >
                            {validFeatures.map((feature, featureIndex) => (
                              <Nav.Item
                                key={`feature-${navIndex}-${featureIndex}`}
                                eventKey={`feature-${navIndex}-${featureIndex}`}
                                onClick={() =>
                                  handleSetRecently(feature.title, feature.link)
                                }
                              >
                                <li
                                  style={{
                                    listStyleType: "disc",
                                    fontSize: "15px",
                                    color: "#B0B0B0",
                                  }}
                                >
                                  <Link
                                    to={feature?.link}
                                    state={{ isAdded: feature?.isAdded }}
                                  >
                                    {feature.title}
                                  </Link>
                                </li>
                              </Nav.Item>
                            ))}
                          </Nav.Menu>
                        );
                      })}
                    </Nav>
                  </Sidenav.Body>
                  <div className="vrhr-logo-container">
                    <img
                      // src="/images/VrHrLogo.svg"
                      src={
                        userDetails?.customerImage
                          ? `data:image/jpeg;base64,${userDetails.customerImage}`
                          : ""
                      }
                      alt="VRHR Logo"
                      className="vrhr-logo"
                    />
                  </div>
                </Sidenav>
              </Sidebar>
            </Container>
          </div>

          <Container>
            <div
              className="dashboard-wrapper"
              style={{
                overflow: "scroll",
                marginLeft: mobile ? 0 : expand ? 195 : 56,
              }}
            >
              <Header>
                <div className="container-fluid">
                  <div className="row">
                    <div className="col-lg-4 col-md-5 d-flex align-items-center">
                      <div className="row align-items-center w-100">
                        <div className="col-xl-3 col-lg-4 col-md-3">
                          <div className="header-icon">
                            <div className="d-flex">
                              <a
                                href="#"
                                //  onClick={()=>context.setIsSideToggleBar(!context.isSideToggleBar)}

                                onClick={() =>
                                  expand == true
                                    ? setExpand(false)
                                    : setExpand(true)
                                }
                              >
                                <img src="/images/icons/xpend.svg" />
                              </a>

                              <a
                                href="#"
                                onClick={handleFavourite2}
                                style={{
                                  cursor: "pointer",
                                  // fontSize: "17px",
                                  color: isFavorite ? "#FFD700" : "#A9A9A9", // Yellow when clicked, grey otherwise
                                }}
                              >
                                {isFavorite ? (
                                  <AiFillStar />
                                ) : (
                                  <AiOutlineStar />
                                )}
                              </a>
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-9 col-lg-8 col-md-8">
                          <div className="page-name d-flex align-items-center">
                            {/* {breadcrumbs.map((breadcrumb, index) => (
                              <React.Fragment key={index}>
                                <Link
                                  to={breadcrumb.path}
                                  className="breadcrumb-item"
                                >
                                  {breadcrumb.name}
                                </Link>
                                {index < breadcrumbs.length - 1 && (
                                  <span className="mx-2">
                                    <FiChevronRight />
                                  </span>
                                )}
                              </React.Fragment>
                            ))} */}

                            <BreadCrum />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-lg-8 col-md-7 d-flex justify-content-end align-items-center">
                      <div className="row align-items-center">
                        <div className="col-md-6 d-flex justify-content-end">
                          <div className="search-bar">
                            <i>
                              <IoSearch />
                            </i>

                            <Form.Control
                              type="text"
                              placeholder="Search"
                              value={searchQuery}
                              onChange={(e) => setSearchQuery(e.target.value)}
                            />
                            {searchQuery && (
                              <List
                                bordered
                                className="position-absolute bg-white w-100 shadow-lg"
                              >
                                {filteredPages.length > 0 ? (
                                  filteredPages.map((page) => (
                                    <List.Item
                                      key={page.route}
                                      className="list-group-item list-group-item-action"
                                      onClick={() => handleSearch(page.route)}
                                      style={{ cursor: "pointer" }}
                                    >
                                      {page.name}
                                    </List.Item>
                                  ))
                                ) : (
                                  <List.Item className="text-muted">
                                    No results found
                                  </List.Item>
                                )}
                              </List>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="header-icon2">
                            <div className="d-flex justify-content-end align-items-center">
                              <Button
                                onClick={() => setOpen(true)}
                                className="h-log"
                              >
                                Logout <MdLogout />
                              </Button>
                              <div style={{ borderRight: "1px solid #d6d6d6" }}>
                                <a href="#">
                                  <img src="/images/icons/notification.svg" />
                                </a>
                              </div>
                              <div>
                                <SelectPicker
                                  data={data}
                                  style={{ width: 224 }}
                                  placement="auto"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Header>

              <div className="responsive-header">
                <div className="responsive-header-inner">
                  <div className="container-fluid">
                    <div
                      className="row align-items-center"
                      style={{ background: "#c9dfff", padding: "0px  17px " }}
                    >
                      <div className="col-6">
                        <div className="header-page-name d-flex">
                          <h2 className="text-brown">Dashboard /</h2>
                          <h2>Default</h2>
                        </div>
                      </div>

                      <div className="col-6">
                        <div className="search-bar w-100">
                          <i>
                            <IoSearch />
                          </i>

                          <Form.Control type="email" placeholder="Search" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="responsive-header-icons">
                  <a
                    className="text-center"
                    href="#"
                    onClick={() => setMobileExpand((val) => !val)}

                    // onClick={() =>
                    //   expand == true
                    //     ? setExpand(false)
                    //     : setExpand(true)
                    // }
                  >
                    <img src="/images/icons/xpend.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/fvrt.png" />
                  </a>
                  <a href="#" className="text-center">
                    <img src="/images/icons/brightness.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/trimer.png" />
                  </a>

                  <a href="#" className="text-center">
                    <img src="/images/icons/notification.png" />
                  </a>

                  <a
                    href="#"
                    className="text-center"
                    onClick={() => setMobileExpand((val) => !val)}
                  >
                    <img src="/images/icons/xpend.png" />
                  </a>
                </div>
              </div>
              <div>
                <AppContent />
              </div>
            </div>
          </Container>
        </div>
      </Container>

      <div className="mini-modal">
        <Modal open={open} onClose={handleClose}>
          <div className="modal-inner">
            <Modal.Body className="text-center">
              <div className="modal-icon">
                <TbLogout size={25} />
              </div>

              <h3>Logout</h3>
              <p>Are you sure you want to logout ?</p>
            </Modal.Body>

            <div className="d-flex">
              <button
                className="btn btn-primary border"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>
              <button
                className="btn btn-primary"
                onClick={() => {
                  Cookies.remove("TopHrToken");
                  Cookies.remove("logs");
                  localStorage.clear();
                  logout();
                  setOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(ProtectedLayout);
