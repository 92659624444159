import React, { useEffect, useState } from "react";
import { Prev } from "react-bootstrap/esm/PageItem";
import { IoIosArrowBack } from "react-icons/io";
import { IoCheckmarkCircle } from "react-icons/io5";
import { useSelector } from "react-redux";

import { useNavigate } from "react-router-dom";
import { Button } from "rsuite";
import api from "../../lib/api";
import { useMutation } from "@tanstack/react-query";
import Cookies from "js-cookie";

const SetupOrganisation = ({ step, setStep }) => {
  const navigate = useNavigate();
  const [zoom, setZoom] = useState(false); // State to track zoom effect
  const { onboardingStatus, userData } = useSelector(
    (state) => state.rootReducer
  );
  console.log("userData redux", userData);
  console.log("Step", step);
  console.log("onboardingStatus", onboardingStatus);

  useEffect(() => {
    if (step === 17) {
      // alert("You have successfully completed the setup process. You can now login to your account.");
      navigate("/dashboard");
    }
  }, [step]);

  const handleOrganisationClick = () => {
    if (step === 1) {
      setStep((prev) => prev + 1);
    } else {
      setStep(6);
    }

    // Trigger the zoom effect
    setZoom(true);
    setTimeout(() => {
      setZoom(false); // Reset zoom after effect duration
    }, 300); // Match this duration with the CSS transition
  };

  const handleStructureClick = () => {
    // Prevent interaction unless in step 1
    if (onboardingStatus.customer) {
      setStep(8); // Or whatever step you want to set for Structure
    }
  };

  const handleDesignationsClick = () => {
    // Prevent interaction unless in step 1
    if (
      onboardingStatus.region == true &&
      onboardingStatus.department == true &&
      onboardingStatus.structureMapping == true
    ) {
      setStep(16); // Or whatever step you want to set for Designations
    }
  };

  useEffect(() => {
    if (userData?.customerId && step == 7) {
      console.log("customerId", userData?.customerId);
      callSetupLogsApi({ level: "customer" });
    }
  }, [userData?.customerId && step == 7]);

  const {
    mutate: callSetupLogsApi,
    isLoading: callSetupLogsApiLoading,
    refetch: refetchCallSetupLogsApi,
  } = useMutation(
    ["setup-log"],
    async (body) =>
      await api.put(
        `master-service/v1/customers/${userData?.customerId}/setup-log`,
        body
      ),
    {
      onSuccess: (res) => {
        // toaster.push(updateMessage, { placement, duration: 5000 });
        // setStep((prev) => prev + 1);
      },
      onError: (err) => {
        console.log("error", err.response);
      },
    }
  );

  return (
    <div>
      <div className="login-bg-new-img">
        <div className="login-page-modals">
          <div className="login-inner-modal">
            {/* {
          step === 1 ? <div></div> :

         
           <div className="back-btn">
            <a
              href="#"
              className="color-light"
              onClick={() => {
                setStep((prev)=> prev - 1);
              }}
            >
              <IoIosArrowBack />
              Back
            </a>
          </div>

          } */}

            <h3>
              Let’s set up your <br className="responsive-br" />
              organisation!
            </h3>
            <div className="login-select-box">
              <div className="container-fluid p-0">
                <div className="row mt-5">
                  <div
                    className="col-sm-4 "
                    // onClick={() => {
                    //     if(step==1){
                    //       setStep((prev)=> prev + 1)
                    //     }else{
                    //       setStep(6)
                    //     }
                    //   }}
                    onClick={handleOrganisationClick}
                  >
                    <div
                      className={`${
                        onboardingStatus.customer
                          ? "login-select-box-inner color-green zoom"
                          : "login-select-box-inner zoom"
                      }`}
                    >
                      <div className="d-flex justify-content-end">
                        <IoCheckmarkCircle
                          style={
                            onboardingStatus.customer
                              ? { color: "#fff" }
                              : { color: "" }
                          }
                        />
                      </div>
                      <img
                        alt=""
                        src={`${
                          onboardingStatus.customer
                            ? "/images/home1_White.svg"
                            : "/images/home1.svg"
                        }`}
                      />
                      <p
                        className={`${
                          onboardingStatus.customer
                            ? "mini-text color-light mt-4 color-white"
                            : "mini-text color-light mt-4"
                        }`}
                      >
                        Organisation Details
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-4" onClick={handleStructureClick}>
                    <div
                      className={`${
                        onboardingStatus.region &&
                        onboardingStatus.department &&
                        onboardingStatus.structureMapping
                          ? "login-select-box-inner color-green zoom"
                          : onboardingStatus.customer
                          ? "login-select-box-inner zoom"
                          : "login-select-box-inner"
                      }`}
                    >
                      <div className="d-flex justify-content-end">
                        <IoCheckmarkCircle
                          style={
                            onboardingStatus.region &&
                            onboardingStatus.department &&
                            onboardingStatus.structureMapping
                              ? { color: "#fff" }
                              : { color: "" }
                          }
                        />
                      </div>
                      <img
                        className="n-img"
                        alt=""
                        src={`${
                          onboardingStatus.region &&
                          onboardingStatus.department &&
                          onboardingStatus.structureMapping
                            ? "/images/Structure-white.svg"
                            : "/images/Structure.svg"
                        }`}
                      />
                      <p
                        className={`${
                          onboardingStatus.region &&
                          onboardingStatus.department &&
                          onboardingStatus.structureMapping
                            ? "mini-text color-light mt-4 color-white"
                            : "mini-text color-light mt-4"
                        }`}
                      >
                        Structure
                      </p>
                    </div>
                  </div>

                  <div className="col-sm-4" onClick={handleDesignationsClick}>
                    <div
                      className={`${
                        onboardingStatus.designation
                          ? "login-select-box-inner color-green zoom"
                          : onboardingStatus.region &&
                            onboardingStatus.department &&
                            onboardingStatus.structureMapping
                          ? "login-select-box-inner zoom"
                          : "login-select-box-inner"
                      }`}
                    >
                      <div className="d-flex justify-content-end">
                        <IoCheckmarkCircle
                          style={
                            onboardingStatus.designation
                              ? { color: "#fff" }
                              : { color: "" }
                          }
                        />
                      </div>
                      <img
                        alt=""
                        src={`${
                          onboardingStatus.designation
                            ? "/images/Designations-white.svg"
                            : "/images/Designations.svg"
                        }`}
                      />
                      <p
                        className={`${
                          onboardingStatus.designation
                            ? "mini-text color-light mt-4 color-white"
                            : "mini-text color-light mt-4"
                        }`}
                      >
                        Designations
                      </p>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <Button
                    className="btn-large mt-5 mb-4 w-50 mr"
                    appearance="primary"
                    onClick={() => {
                      if (step === 1) {
                        setStep((prev) => prev + 1);
                      } else if (
                        onboardingStatus.customer &&
                        onboardingStatus.region &&
                        onboardingStatus.department &&
                        onboardingStatus.designation &&
                        onboardingStatus.structureMapping
                      ) {
                        setStep(17);
                      }
                    }}
                    disabled={
                      onboardingStatus.customer &&
                      onboardingStatus.region &&
                      onboardingStatus.department &&
                      onboardingStatus.designation &&
                      onboardingStatus.structureMapping
                        ? false
                        : true
                    }
                  >
                    {step === 1 ? (
                      <div>Get Started</div>
                    ) : onboardingStatus.customer &&
                      onboardingStatus.region &&
                      onboardingStatus.department &&
                      onboardingStatus.designation &&
                      onboardingStatus.structureMapping ? (
                      <div>Go To Dashboard</div>
                    ) : (
                      <div>Next</div>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupOrganisation;
