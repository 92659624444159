import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// const customerData = JSON.parse(localStorage.getItem("customerData"));
// console.log(customerData,)

const customerData = JSON.parse(localStorage.getItem("customerData")) || {};
console.log(customerData,"GETDATA")



const initialState = {
  customerInfo: "",
  customerId: null,
  test: "",
  userData: customerData, 

  onboardingStatus:
    localStorage.getItem("onboardingStatus") == undefined
      ? {
          customer: false,
          region: false,
          department: false,
          structureMapping: false,
          designation: false,
        }
      : JSON.parse(localStorage.getItem("onboardingStatus")),
};

const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    customerInfo: (state, action) => {
      state.customerInfo = action.payload;
    },
    customerId: (state, action) => {
      state.customerId = action.payload;
    },
    testData: (state, action) => {
      state.test = action.payload;
    },
    handleOnboardingStatus: (state, action) => {
      if (action.payload !== undefined) {
        state.onboardingStatus = {
          ...state.onboardingStatus,
          ...action.payload,
        };
        localStorage.setItem(
          "onboardingStatus",
          JSON.stringify(state.onboardingStatus)
        );
      }
    },
    setUserData: (state, action) => {
      state.userData = action.payload || {};
      if (!action.payload) {
        localStorage.removeItem("customerData"); 
      } else {
        localStorage.setItem("customerData", JSON.stringify(state.userData));
      }
    },
    
  },
});

export const { customerInfo, testData, customerId, handleOnboardingStatus,setUserData  } =
  userSlice.actions;
export default userSlice.reducer;
