import React, { useState } from "react";
import { Button, Modal } from "rsuite";

function ConfirmDelete({
  deleteObject,
  handledeleteObject,
  setOpenDeleteModal,
  openDeleteModal,
  title,
  msg1,
  msg2,
  btn1,
  btn2,
}) {
  return (
    <div>
      <Modal open={openDeleteModal} onClose={() => setOpenDeleteModal(false)}>
        <Modal.Header>
          <Modal.Title
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img
              src="/images/icons/deleteIcon.svg"
              height={48}
              width={48}
              style={{ marginBottom: "10px" }}
            />{" "}
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteObject && deleteObject?.child?.length > 0 ? (
            <p>
              <h6>
                {msg1}
                {/* {deleteObject?.child.map((child =>  <p key={child.id}>{child.name}</p>))} */}
              </h6>
            </p>
          ) : (
            <p>
              <h6>
                {msg2} {deleteObject?.name} !{" "}
              </h6>
            </p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              gap: "8px",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={() => setOpenDeleteModal(false)}
              style={{
                background: "#F14141",
                color: "white",
                fontWeight: "600",
                fontSize: "14px",
              }}
              className="btn-large"
              // disabled={deleteObject?.children?.length > 0 ? true : false}
            >
              {btn1}
            </Button>
            <Button
              onClick={() => handledeleteObject()}
              style={{ fontWeight: "600", fontSize: "14px", color: "#A4A4A4" }}
              // appearance="primary"
              appearance="default"
              className="btn-large"
            >
              {btn2}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ConfirmDelete;
