import { FiChevronRight } from "react-icons/fi";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";

const BreadCrum = () => {
  const breadcrumDetails = useSelector(
    (state) => state.breadcrumReducer.breadcrum
  );
  const breadcrumbKey = useMemo(
    () => breadcrumDetails.map((item) => item.label).join("-"),
    [breadcrumDetails]
  );

  const variants = {
    hidden: { opacity: 0, x: -20 },
    visible: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: 20 },
  };

  return (
    <AnimatePresence mode="wait">
      <motion.span
        key={breadcrumbKey}
        style={{
          fontSize: 14,
          textTransform: "capitalize",
          display: "flex",
          alignItems: "center",
          whiteSpace: "nowrap",
        }}
        initial="hidden"
        animate="visible"
        exit="exit"
        variants={variants}
        transition={{
          duration: 0.3,
          type: "tween",
        }}
      >
        {breadcrumDetails?.map((item, i) => (
          <React.Fragment key={`${breadcrumbKey}-${i}`}>
            <motion.span
              style={{
                color:
                  i === breadcrumDetails.length - 1
                    ? "#000000"
                    : i === 0
                    ? "grey"
                    : "rgba(0, 0, 0, 0.7)",
              }}
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: 20 }}
              transition={{
                duration: 0.3,
                delay: i * 0.1,
                type: "tween",
              }}
            >
              {item.link !== "" ? (
                <Link
                  to={item.link}
                  style={{
                    cursor: "pointer",
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  {item.label}
                </Link>
              ) : (
                <span>{item.label}</span>
              )}
            </motion.span>
            {i < breadcrumDetails.length - 1 && (
              <FiChevronRight style={{ margin: "0 10px", color: "black" }} />
            )}
          </React.Fragment>
        ))}
      </motion.span>
    </AnimatePresence>
  );
};

export default BreadCrum;
