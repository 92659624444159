import axios from "axios";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { getNavigate } from "../utils/Navigation";

const api = axios.create({
  // baseURL: "http://localhost:8080/api/",
  // baseURL: "https://app.tophr.co.in/api/",
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(function (config) {
  // Do something before request is sent
  let token = Cookies.get("TopHrToken");
  if (token) {
    // config.headers.append(
    //   "Access-Control-Allow-Origin",
    //   "https://app.tophr.co.in/api/"
    // );
    // config.headers.append("Access-Control-Allow-Credentials", "true");

    config.headers["token"] = `${token}`;
    // config.headers["Authorization"] = `Bearer ${token}`;
  }
  return config;
});

// api.interceptors.response.use(
//   (response) => response,
//   (error) => {
//     console.log("Error status:", error.response?.status);

//     const status = error.response?.status;
//     if (status === 401) {
//       console.log("401 detected, logging out...");
//       localStorage.removeItem("user");
//       Cookies.remove("TopHrToken");

//       const navigate = getNavigate();
//       if (navigate) {
//         navigate("/login");
//       }
//     }

//     return Promise.reject(error);
//   }
// );

api.interceptors.response.use(
  (response) => response, // Pass the response normally
  (error) => {
    console.log("Interceptor triggered with error:", error); // Debugging log
    const status = error.response?.status;

    // Prevent infinite loop by skipping for `/login`
    if (status === 401 && window.location.pathname !== "/login") {
      console.log("401 detected, logging out...");
      localStorage.removeItem("user");
      // Cookies.remove("TopHrToken");
      Cookies.remove("TopHrToken", { path: "/" });
      window.location.reload();

      // const navigate = getNavigate();
      // if (navigate) {
      //   console.log("Redirecting to /login...");
      //   navigate("/login");
      // }
    }

    return Promise.reject(error); // Pass the error to the caller
  }
);

export default api;
