import PeoplesIcon from "@rsuite/icons/Peoples";
import { BsFileEarmarkText } from "react-icons/bs";
import { LiaUserClockSolid } from "react-icons/lia";
import { FaHandHoldingWater } from "react-icons/fa";
import { PiChartPieSlice } from "react-icons/pi";

export const navModule = [
  {
    title: "Attendance",
    icon: <PiChartPieSlice className="new-icn" />,
    menu: [
      {
        title: "Configuration",
        link: "/AttendanceConfiguration",
      },

      // {
      //   title: "Team Attendance",
      //   link: "/Team-Attendance",
      // },

      // {
      //   title: "Manual Attendance",
      //   link: "/Manual-Attendance",
      // },

      // {
      //   title: "Attendance Approval",
      //   link: "/BulkUpload",
      // },

      {
        title: "Bulk Upload",
        link: "/Module/Attendance/bulkUpload",
      },

      {
        title: "Workflow",
        link: "/Workflow",
      },
    ],
  },
  {
    title: "Leave",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Configurations",
        link: "/LeaveConfiguration",
      },
      {
        title: "Leave Types",
        link: "/leaveTypes",
      },

      {
        title: "Leave Balance",
        link: "/leavebalance",
      },

      {
        title: "Manage Leave",
        link: "/Manage-Leaves",
      },

      {
        title: "Holiday List",
        link: "/holiday-settings",
      },
      {
        title: "Bulk Upload",
        link: "/bulkUploadLeave",
      },
      {
        title: "Workflow",
        link: "/Workflow",
      },
      {
        title: "Leave Application",
        link: "/leaveApplication",
      },

      {
        title: "Leave Approvals",
        link: "/LeaveApprovals",
      },

      {
        title: "Apply Leave",
        link: "/applyLeave",
      },

      {
        title: "Application Details",
        link: "/applicationDetails",
      },
    ],
  },
  {
    title: "Payroll",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Configurations",
        link: "/PayrollConfiguration",
      },
      {
        title: "Payroll Structures",
        link: "/PayrollStructure",
      },
      {
        title: "Component Groups",
        link: "/ComponentGroups",
      },
      {
        title: "Components",
        link: "/Components",
      },
      {
        title: "User Structure Mapping",
        link: "/UserStructureMapping",
      },
      {
        title: "Employee Pay Structure",
        link: "/Employee-Pay-Structure",
      },
      {
        title: "Payroll Cycle",
        link: "/PayrollCycle",
      },
      {
        title: "Employee pay period",
        link: "/PayPeriod",
      },

      {
        title: "Employee PaySlips",
        link: "/Employee-Pay-Slips",
      },
    ],
  },

  {
    title: "Loans",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Loan Types",
        link: "/LoanTypes",
      },
      {
        title: "Loan Request",
        link: "/LoanRequest",
      },
      {
        title: "Loan OverView",
        link: "/LoanOverView",
      },

     
    ],
  },
  {
    title: "Advances",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Advance Requests",
        link: "/AdvanceRequests",
      },
      {
        title: "Advance OverView",
        link: "/AdvanceOverView",
      },

    
    ],
  },
];
