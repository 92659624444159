import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './userSlice'; // adjust the import based on your structure
import breadcrumReducer from './breadcrumSlice'; // adjust the import based on your structure
import reducer from './userSlice';

const store = configureStore({
    reducer : {
        rootReducer: reducer,
        breadcrumReducer: breadcrumReducer
    },
});

export default store;
