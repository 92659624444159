import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  breadcrum: [],
};

export const breadcrumSlice = createSlice({
  name: "breadcrumDetails",
  initialState,
  reducers: {
    setBreadCrum: (state, action) => {
      state.breadcrum = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setBreadCrum } = breadcrumSlice.actions;

export default breadcrumSlice.reducer;
