import { LiaUserClockSolid } from "react-icons/lia";
import { BsFileEarmarkText } from "react-icons/bs";
import { PiChartPieSlice } from "react-icons/pi";

export const reports = [
  {
    title: "Attendance Reports",
    icon: <PiChartPieSlice className="new-icn" />,

    menu: [
      {
        title: "Mustor Roll",
        link: "/master-roll",
      },
      {
        title: "Monthly Report",
        link: "/MonthlyReport",
      },
      {
        title: "Daily Report",
        link: "/Daily-Reports",
      },

      {
        title: "Deviation Report",
        link: "/Deviation-Reports",
      },

      {
        title: "Attendance Logs",
        link: "/Attendance-Logs",
      },
    ],
  },
];
