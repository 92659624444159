import DragableIcon from "@rsuite/icons/Dragable";
import ChangeListIcon from "@rsuite/icons/ChangeList";
import DashboardIcon from "@rsuite/icons/Dashboard";
import GroupIcon from "@rsuite/icons/legacy/Group";
import PeoplesIcon from "@rsuite/icons/Peoples";
import SiteFillIcon from "@rsuite/icons/SiteFill";
import GridIcon from "@rsuite/icons/Grid";
import { addClass } from "rsuite/esm/DOMHelper";
import { PiChartPieSlice } from "react-icons/pi";

export const nav = [
  // {
  //   title: "Overview",
  //   addClass:"new"
  // },

  {
    title: "Overview",
    link: "/Dashboard",
    icon: <PiChartPieSlice/>,
  },


  // {
  //   title: "eCommerce",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Opening Balance",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Account Master",
  //       link: "/account-master",
  //     },
  //   ],
  // },
 

  // {
  //   title: "User Profile",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Overview",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Projects",
  //       link: "/account-master",
  //     },
  //     {
  //       title: "Campaigns",
  //       link: "/account-master",
  //     },
  //     {
  //       title: "Documents",
  //       link: "/account-master",
  //     },
  //     {
  //       title: "Followers",
  //       link: "/account-master",
  //     },
  //   ],
  // },

  // {
  //   title: "Account",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Overview",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Projects",
  //       link: "/account-master",
  //     },
     
  //   ],
  // },
  // {
  //   title: "Corporate",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Overview",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Projects",
  //       link: "/account-master",
  //     },
     
  //   ],
  // },
  // {
  //   title: "Blog",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Overview",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Projects",
  //       link: "/account-master",
  //     },
     
  //   ],
  // },
  // {
  //   title: "Social",
  //   icon: <PeoplesIcon />,
  //   menu: [
  //     {
  //       title: "Overview",
  //       link: "/opening-balance",
  //     },
  //     {
  //       title: "Projects",
  //       link: "/account-master",
  //     },
     
  //   ],
  // },


];
