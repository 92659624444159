import React, { useEffect, useState } from "react";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  ButtonToolbar,
  Input,
  Loader,
  Message,
  Modal,
  SelectPicker,
  useToaster,
} from "rsuite";
import { TiDelete } from "react-icons/ti";
import Accordion from "react-bootstrap/Accordion";
import api from "../../lib/api";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
// import Accordion,{  Placeholder } from 'rsuite';
import { MdEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa6";
import ConfirmDelete from "./ConfirmDelete";
import Cookies, { set } from "js-cookie";
import NotificationMessage from "../NotificationMessage";
import { handleOnboardingStatus } from "../../Redux/userSlice";
import { useDispatch, useSelector } from "react-redux";

const SetupRegions = ({ step, setStep, refetch = () => {} }) => {
  // const [regionsArray, setRegionsArray] = useState([]);
  const toaster = useToaster();
   const dispatch = useDispatch();
  const [placement, setPlacement] = React.useState("bottomEnd");
  const [regionParentId, setRegionParentId] = useState(0);
  const [newRegionArray, setNewRegionArray] = useState([]);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteObject, setDeleteObject] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [editId, setEditId] = useState(0);
  const [editedName, setEditedName] = useState("");
  const [parentIdForUpdate, setParentIdForUpdate] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [activeKey, setActiveKey] = useState({});
  const [openItems, setOpenItems] = useState({});
  const [activeParentId, setActiveParentId] = useState(null);
  const [updatedKey, setUpdatedKey] = useState(false);
  const [isRegionAdded, setIsRegionAdded] = useState(false); // Track if a region has been added
 
 console.log("step", step);

   const {onboardingStatus,userData} = useSelector((state) => state.rootReducer);
  console.log("userData redux", userData);

  const addMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region added successfully"} </strong>
    </Message>
  );
  const addErrorMessage = (errorMessage) => (
    <Message showIcon type="error" closable>
      <strong>{errorMessage}</strong>
    </Message>
  );
  const deleteMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region delete successfully"} </strong>
    </Message>
  );
  const updateMessage = (
    <Message showIcon type={"success"} closable>
      <strong>{"Region update successfully"} </strong>
    </Message>
  );

  const {
    register,
    handleSubmit,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm();



  const {
    data: regionsArray,
    isLoading: getAllRegionLoading,
    refetch: refetchApi,
  } = useQuery(
    ["getAllRegion"],
    () =>
      api.get(
        `master-service/v1/customers/${userData?.customerId}/region?page=0&size=1000&sort=id,desc&name=&searchValue=`
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      enabled: true,
      select: (res) => res.data.content,
    }
  );

  useEffect(() => {
    if (regionsArray && regionsArray.length > 0) {
      setIsRegionAdded(true);
    }
  }, [regionsArray]);

  const flattenRegions = (regions) => {
    let flatList = [];

    const recurse = (regionList) => {
      regionList.forEach((region) => {
        flatList.push({ label: region.name, value: region.id });
        if (region.child && region.child.length > 0) {
          recurse(region.child);
        }
      });
    };

    recurse(regions);
    return flatList;
  };

  console.log("regionsArray", regionsArray);

  const handleAddRegions = (data) => {
    addRegions({
      name: data.regionName,
      customerId: userData?.customerId,
      parentId:
        regionsArray == []
          ? 0
          : regionParentId != 0 && regionParentId != null
          ? regionParentId
          : 0,
    });
  };

  const { mutate: addRegions, isLoading: addRegionLoading } = useMutation(
    ["addRegion"],
    async (body) => await api.post(`master-service/v1/regions`, body),
    {
      onSuccess: (res) => {
        console.log(res?.data, "posting-region");
        if (res?.data?.statusCode === 200) {
          callSetupLogsApi({ level: "region" })
          refetchApi();
          setRegionParentId(0);
          reset();
          setUpdatedKey(true);
          setIsRegionAdded(true);
          dispatch(handleOnboardingStatus({ region: true }));
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        } else {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }

        // toaster.push(addMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
        const errorMessage =
          err.response?.data?.message ||
          "An error occurred while adding the region.";
        if (errorMessage.includes("Region already exists")) {
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={errorMessage}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        } else {
          // Handle general error
        
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description="An error occurred while adding the region"
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }

        // if (errorMessage && errorMessage.includes("Region already exists")) {
        //   toaster.push(addErrorMessage(errorMessage), {
        //     placement,
        //     duration: 5000,
        //   });
        // } else {
        //   toaster.push(
        //     addErrorMessage("An error occurred while adding the region."),
        //     { placement, duration: 5000 }
        //   );
        // }
      },
    }
  );

  useEffect(() => {
    if (regionsArray && regionsArray.length > 0) {
      // If regions already exist, enable the Continue button
      setIsRegionAdded(true);
    } else {
      // If no regions exist, disable the Continue button
      setIsRegionAdded(false);
    }
  }, [regionsArray]);

  const {
    mutate: deleteRegion,
    isLoading: deleteRegionLoading,
    refetch: refetchdeleteRegion,
  } = useMutation(
    ["deleteRegion"],
    // async (body) => await api.delete(`masterRegion/web/deleteRegion/${body}`),
    async (body) =>
      await api.delete(
        `master-service/v1/customers/${userData?.customerId}/regions/${body}`
      ),
    {
      onSuccess: (res) => {

        if(res?.data?.statusCode === 200){
          setNewRegionArray((prev) =>
            prev.filter((region) => {
              const isDeletedRegion = region.id === deleteObject.id;
              const isChildOfDeletedRegion = region.parentId === deleteObject.id;
              return !isDeletedRegion && !isChildOfDeletedRegion;
            })
          );
          refetchApi();
          setUpdatedKey(true);
          const successMessage = res?.data?.message || "Region deleted successfully!";

          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={res?.data?.message}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 20000 }
          );
        }else{

          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={res?.data?.message || "Something went wrong during deletion."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,
            { placement: "bottomEnd", duration: 5000 }
          );
        }
        
        // toaster.push(deleteMessage, { placement, duration: 5000 });
      },
      onError: (err) => {
        console.log("error", err.response);
        const notificationKey = toaster.push(
          <NotificationMessage
            type="error"
            title="Error!"
            description={err?.response?.data?.message}
            onClose={() => toaster.remove(notificationKey)} // Close the toaster when the close icon is clicked
          />,
          { placement: "bottomEnd", duration: 5000 }
        );
      },
    }
  );

  const {
    mutate: updateRegion,
    isLoading: updateRegionLoading,
    refetch: refetchupdateRegion,
  } = useMutation(
    ["updateRegion"],
    async (body) => {
      console.log("Updating with body:", body);
      return await api.put(`master-service/v1/regions/${editId}`, body);
      //updateRegion(body);
    },
    {
      onSuccess: (res) => {
        const successMessage = res.data.message;
        if (res?.data?.statusCode === 200) {
          refetchApi();
          setIsEdit(false);
          setEditId(0);
          setEditedName("");
          setParentIdForUpdate(0);
          setUpdatedKey(true);
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={successMessage}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
          // toaster.push(updateMessage, { placement, duration: 5000 });
        }
      },
      onError: (err) => {
        console.log("error", err.response);
        const errorMessage = err.response?.data?.message;
        if (errorMessage.includes("Region already exists")) {
         
          const notificationKey = toaster.push(
            <NotificationMessage
              type="success"
              title="Success!"
              description={ errorMessage}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        } else {
          // Handle general error
          // toaster.push(
          //   <NotificationMessage
          //     type="error"
          //     title="Error!"
          //     description="An error occurred while adding the region."
          //   />,
          //   { placement: "bottomEnd", duration: 5000 }
          // );
          const notificationKey = toaster.push(
            <NotificationMessage
              type="error"
              title="Error!"
              description={"An error occurred while adding the region."}
              onClose={() => toaster.remove(notificationKey)} // Close the toaster when close icon is clicked
            />,

            { placement: "bottomEnd", duration: 5000 }
          );
        }
      },
    }
  );

  const {
    mutate: callSetupLogsApi,
    isLoading: callSetupLogsApiLoading,
    refetch: refetchCallSetupLogsApi,
  } = useMutation(
    ["setup-log"],
    async (body) =>
      await api.put(
        `master-service/v1/customers/${userData?.customerId}/setup-log`,
        body
      ),
    {
      onSuccess: (res) => {
        
      },
      onError: (err) => {
       
      },
    }
  );
  useEffect(() => {
    if (regionsArray?.length > 0) {
      setNewRegionArray(regionsArray);
    }
  }, [regionsArray]);
  useEffect(() => {
    if (updatedKey) {
      refetch();
    }
  });

  const handleDeleteRegion = () => {
    setOpenDeleteModal(false);
    deleteRegion(deleteObject.id);
  };

  // useEffect(() => {
  //   if (regionsArray?.length > 0) {
  //     console.log("transformData", transformData(regionsArray));
  //     setNewRegionArray(transformData(regionsArray));
  //   }
  // }, [regionsArray]);

  const handleUpdate = () => {
    console.log("region", editId);
    console.log("editedName", editedName);

    // Check if editId and editedName are valid
    if (editId && editedName) {
      const body = {
        // id: editId,
        name: editedName,
        parentId: parentIdForUpdate || 0,
        customerId: userData?.customerId,
      };
      console.log("Preparing to update with body:", body);
      updateRegion(body);
    }
  };

  console.log("newRegionArray", newRegionArray);

  const handleBlur = () => {
    if (isEdit) {
      handleUpdate();
    }
    setIsEdit(false);
  };

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
      setIsEdit(false);
    }
  };

  // const handleToggle = (id) => {
  //   setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
  // };

  // Replace the existing handleToggle function with this one
  const handleToggle = (id) => {
    // Find the item to determine if it's a parent or child
    const findItem = (data, targetId) => {
      for (let item of data) {
        if (item.id === targetId) {
          return item;
        }
        if (item.child) {
          const found = findItem(item.child, targetId);
          if (found) return found;
        }
      }
      return null;
    };

    const item = findItem(newRegionArray, id);

    // If item is not found, return
    if (!item) return;

    // Check if this is a parent item (no parentId)
    const isParent = !item.parentId;

    if (isParent) {
      // If clicking a different parent than the active one
      if (activeParentId !== id) {
        // Close all other accordions
        const newOpenItems = {};
        newOpenItems[id] = true;
        setOpenItems(newOpenItems);
        setActiveParentId(id);
      } else {
        // Clicking the same parent - just toggle it
        setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
        setActiveParentId((prev) => (prev === id ? null : id));
      }
    } else {
      // For child items, just toggle normally
      setOpenItems((prev) => ({ ...prev, [id]: !prev[id] }));
    }
  };

  // const transformData = (data) => {
  //   // Create a map to hold the items by their ID

  //   const map = {};
  //   data.forEach((item) => {
  //     map[item.id] = { ...item, children: [] };
  //   });

  //   // Iterate through the items and populate the children arrays
  //   const rootItems = [];
  //   data.forEach((item) => {

  //     if (item.parent)  {
  //       console.log(item);

  //       const parent = map[item.parent.id];
  //       if (parent) {
  //         parent.children.push(map[item.id]);

  //       }
  //     } else {
  //       console.log("helloooooooo");
  //       rootItems.push(map[item.id]);

  //     }
  //   });

  //   console.log("rootItems", rootItems);

  //   return rootItems;
  // };

  console.log("parent", regionParentId);

  // AccordionItem component
  const AccordionItem = ({ item, level = 0 }) => {
    return (
      <Accordion.Item eventKey={item.id.toString()}>
        <Accordion.Header
          onClick={() => handleToggle(item.id)}
          className={item.child?.length > 0 ? "" : "hideIcon"}
        >
          <div className="strip mb-1" onClick={(e) => e.stopPropagation()}>
            <div className="d-flex align-items-center">
              <div>
                <p className="data-text">
                  {isEdit && editId === item.id ? (
                    <Input
                      onClick={(e) => e.stopPropagation()}
                      value={editedName}
                      onBlur={handleBlur}
                      onKeyDown={handleEnterKeyPress}
                      autoFocus={true}
                      onChange={(value) => setEditedName(value)}
                    />
                  ) : (
                    item.name
                  )}
                </p>
              </div>
            </div>
            <div className="d-flex align-items-center">
              {/* <div>
                <FaPlus className="strip-icon" />
              </div> */}
              <div
                onClick={(e) => {
                  e.stopPropagation();
                  setEditedName(item.name);
                  setIsEdit(true);
                  setEditId(item.id);
                  setParentIdForUpdate(item.parentId || 0);
                }}
              >
                <MdEdit className="strip-icon" />
              </div>
              <div
                onClick={() => {
                  setDeleteObject(item);
                  setOpenDeleteModal(true);
                }}
              >
                <TiDelete />
              </div>
            </div>
          </div>
        </Accordion.Header>
        <Accordion.Body>
          {item.child?.length > 0 && (
            <NestedAccordion data={item.child} level={level + 1} />
          )}
        </Accordion.Body>
      </Accordion.Item>
    );
  };

  // Main NestedAccordion component
  const NestedAccordion = ({ data, level = 0 }) => {
    return (
      <Accordion
        activeKey={Object.keys(openItems).filter((key) => openItems[key])}
      >
        {data.map((item) => (
          <AccordionItem key={item.id} item={item} level={level} />
        ))}
      </Accordion>
    );
  };
  return (
    <div className="main-div">
      {getAllRegionLoading || addRegionLoading || deleteRegionLoading ? (
        <div className="loader">
          {" "}
          <Loader size="sm" colour="#00000" />
        </div>
      ) : (
        ""
      )}
      <div className="login-bg-new-img">
        <div className="login-page-modals new-width">
          <div className="login-inner-modal modal-5">
            <div className="back-btn">
              <a
                href="#"
                className="color-light"
                onClick={() => onboardingStatus.region ? setStep(10) : setStep((prev) => prev - 1)}
              >
                <IoIosArrowBack />
                Back
              </a>
            </div>
            <h3 className="mt-3">Setup Regions </h3>
            <p className="my-3">Create Regions as per your organisation</p>

            <form onSubmit={handleSubmit(handleAddRegions)}>
              <div>
                <div className="align-items-end d-flex">
                  <div className="container-fluid p-0">
                    <div className="row align-items-end">
                      <div className="col-md-5">
                        <div className=" new-margin">
                          <label>Regions Name </label>

                          <input
                            placeholder="Enter Regions name"
                            style={{ width: "150px !important" }}
                            type="text"
                            {...register("regionName", {
                              required: "Region Name is required",
                            })}
                          />
                        </div>
                        <span className=" d-block requird-color">
                          {errors.regionName && errors.regionName.message}
                        </span>
                      </div>

                      <div className="col-md-5">
                        <div className="new-margin">
                          <label>Parent Regions </label>
                          <SelectPicker
                            data={flattenRegions(newRegionArray)}
                            searchable={true}
                            style={{ width: 224 }}
                            placeholder="Select Region"
                            value={regionParentId}
                            onChange={(val) => {
                              console.log("val", val);
                              setRegionParentId(val);
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div>
                          <Button
                            className="btn-large"
                            style={{ margin: "0 -10px" }}
                            appearance="primary"
                            type="submit"
                          >
                            Add New
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                 {isRegionAdded && (
                   <div className="organisation-structure-data">
                   <p className="data-text mt-4">List of Regions</p>

                   <div className="scroll-container">
                     <div className="scroll-content">
                       <NestedAccordion data={newRegionArray} />
                     </div>
                   </div>
                 </div>
                 )}
                  
                  
                </div>
                <div className="text-center">
                  <Button
                    style={{ bottom: "10px", top:"20px"}}
                    className="btn-large w-50 "
                    appearance="primary"
                    onClick={() => setStep((prev) => prev + 1)}
                    // onClick={() => callSetupLogsApi({ level: "region" })}
                    disabled={!isRegionAdded} // Disable button until a region is added
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </form>
            <ConfirmDelete
              deleteObject={deleteObject}
              handledeleteObject={handleDeleteRegion}
              setOpenDeleteModal={setOpenDeleteModal}
              openDeleteModal={openDeleteModal}
              title="Delete Confirmation"
              msg1="Deleting the parent will also delete all associated child records. Do you want to continue?"
              msg2="Are you sure you want to delete this?"
              btn1="Cancel"
              btn2="Delete"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupRegions;
